import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Container, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ReportModal from '../../components/modals/ReportModal';
import MedRecFalloutDashboard from '../../components/dashboards/MedRecFalloutDashboard';
import DownloadHelpIcon from '../../components/DownloadHelpIcon/DownloadHelpIcon';


const MedRecReports = () => {

    const idToken = useSelector((state) => state.tokens.idToken);
    const accessFallout = useSelector((state) => state.tokens.falloutPermission);
    const [embedUrlFlag, setEmbedUrlFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isMountedRef = useRef(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (name, attributes) => {
        setModalName(name);
        setDashAttributes(attributes);
        setShow(true);
    }
    const [modalName, setModalName] = useState("");
    const [dashAttributes, setDashAttributes] = useState();

    const [showFallout, setShowFallout] = useState(false);

    //dev
    // const [medRecDashboards, setMedRecDashboards] = useState([
    //     { 'name': 'MedRec Conformance Report', 'id': '721728db-81d9-4d58-8b0f-53d815d1e0d7', 'embedAttributes': '', 'description': 'Describes overall conformance rates for C-CDA messages sent.' },
    // ])

    //upper env
    // const [medRecDashboards, setMedRecDashboards] = useState([
    //     { 'name': 'MedRec Conformance Report', 'id': 'd16b6e79-ec12-4276-8570-58a99368dcc5', 'embedAttributes': '', 'description': 'Describes overall conformance rates for C-CDA messages sent.' },
    // ])

    //prod env
    const [medRecDashboards, setMedRecDashboards] = useState([
        { 'name': 'MedRec Conformance Report', 'id': 'mi__medrec_conformance__PROD', 'embedAttributes': '', 'description': 'Describes overall conformance rates for C-CDA messages sent.' }
    ])

    useEffect(() => {
        isMountedRef.current = true;

        if (embedUrlFlag) {
            setIsLoading(false);
        }

        return () => (isMountedRef.current = false);
    }, [embedUrlFlag]);

    useEffect(() => {
        isMountedRef.current = true;
        const getEmbedUrl = () => {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Authorization', idToken);
            medRecDashboards.forEach((dashboard, index) => {
                let raw = JSON.stringify({
                    dashboardId: dashboard.id
                });
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };
                if (process.env.REACT_APP_CONFORMANCE_DASHBOARD_URL_API) {
                    fetch(process.env.REACT_APP_CONFORMANCE_DASHBOARD_URL_API, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            const parsedResult = JSON.parse(result);

                            let tempList = [...medRecDashboards];
                            tempList[index].embedAttributes = {
                                src: parsedResult.embedUrl,
                                width: '98%',
                                height: '660',
                                frameBorder: 1,
                                id: 'idIframe' + dashboard.id,
                            };

                            setMedRecDashboards(tempList);
                            setEmbedUrlFlag(true);

                        }).catch((error) => {
                            console.log("Error loading dashboards " + error);
                            setEmbedUrlFlag(true);

                            let tempList = [...medRecDashboards];
                            tempList[index].embedAttributes = {
                                src: '',
                                width: '98%',
                                height: '660',
                                frameBorder: 1,
                                id: 'idIframe' + dashboard.id,
                            };

                            setMedRecDashboards(tempList);
                        });
                }
            })

        };
        getEmbedUrl();
        return () => (isMountedRef.current = false);
    }, [idToken]);

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        top: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#223c89',
                    }}
                >
                    <Loader type="ThreeDots" color="#223c89" height="80" width="80" />
                </div>
            )}
            {!isLoading &&
                <Container>
                    <h1>MedRec Conformance Reports</h1>
                    <Row>
                        <Col className="text-center">
                            <h6 style={{ marginBottom: '16px' }}>Please select from the following report options:</h6>
                        </Col>
                    </Row>
                    <Row xs={1} md={2} className="g-4">
                        {medRecDashboards.map((dashboard, index) => {
                            if (dashboard.name === "MedRec Fallout Report" && accessFallout === "false") {
                                return "";
                            }
                            else {
                                if (dashboard.embedAttributes) {
                                    return (
                                        <Col className="item" key={index}>
                                            <OverlayTrigger
                                                key={index}
                                                placement="bottom"
                                                delay={{ hide: 100 }}
                                                overlay={
                                                    <Tooltip id={index}>
                                                        {dashboard.description}
                                                    </Tooltip>
                                                }
                                            >
                                                <Card>
                                                    <Card.Header as="h5" className="text-center" onClick={() => handleShow(dashboard.name, dashboard.embedAttributes)}>{dashboard.name}</Card.Header>
                                                </Card>
                                            </OverlayTrigger>
                                        </Col>
                                    )
                                }
                            }
                            return "";
                        })}
                        <Col>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ hide: 100 }}
                                overlay={
                                    <Tooltip>
                                        Shows C-CDA messages that did not meet the conformance standards.
                                    </Tooltip>
                                }
                            >
                                <Card>
                                    <Card.Header as="h5" className="text-center" onClick={() => setShowFallout(true)}>MedRec Fallout Report</Card.Header>
                                </Card>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Container>
            }
            <Modal show={show} onHide={handleClose} fullscreen>
                <ReportModal modalName={modalName} dashAttributes={dashAttributes} />
            </Modal>
            {accessFallout &&
                <>
                    <Modal show={showFallout} onHide={() => setShowFallout(false)} fullscreen>
                        <Modal.Header closeButton>
                            <Modal.Title>MedRec Fallout Report</Modal.Title>
                            <DownloadHelpIcon />
                        </Modal.Header>
                        <Modal.Body>
                            <MedRecFalloutDashboard />
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    );
};

export default MedRecReports;
