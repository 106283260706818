import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Container, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ReportModal from '../../components/modals/ReportModal';

const MedRecReports = () => {

    const idToken = useSelector((state) => state.tokens.idToken);
    const [embedUrlFlag, setEmbedUrlFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isMountedRef = useRef(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (name, attributes) => {
        setModalName(name);
        setDashAttributes(attributes);
        setShow(true);
    }
    const [modalName, setModalName] = useState("");
    const [dashAttributes, setDashAttributes] = useState();

    //dev
    // const [labDashboards, setLabDashboards] = useState([
    //     { 'name': 'Statewide Lab Conformance Report', 'id': '1234', 'embedAttributes': '', 'description': 'Describes overall conformance rates for lab ORU messages sent.' },
    // ])

    //preprod env
    // const [labDashboards, setLabDashboards] = useState([
    //     { 'name': 'Statewide Lab Conformance Report', 'id': 'labconformance_PREPROD', 'embedAttributes': '', 'description': 'Describes overall conformance rates for lab ORU messages sent. ' },
    // ])

    //prod env
    const [labDashboards, setLabDashboards] = useState([
        { 'name': 'Statewide Lab Conformance Report', 'id': 'labconformance_PROD', 'embedAttributes': '', 'description': 'Describes overall conformance rates for lab ORU messages sent. ' },
    ])

    useEffect(() => {
        isMountedRef.current = true;

        if (embedUrlFlag) {
            setIsLoading(false);
        }

        return () => (isMountedRef.current = false);
    }, [embedUrlFlag]);

    useEffect(() => {
        isMountedRef.current = true;
        const getEmbedUrl = () => {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Authorization', idToken);
            labDashboards.forEach((dashboard, index) => {
                let raw = JSON.stringify({
                    dashboardId: dashboard.id
                });
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };
                if (process.env.REACT_APP_CONFORMANCE_DASHBOARD_URL_API) {
                    fetch(process.env.REACT_APP_CONFORMANCE_DASHBOARD_URL_API, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            const parsedResult = JSON.parse(result);
                            let tempList = [...labDashboards];
                            tempList[index].embedAttributes = {
                                src: parsedResult.embedUrl,
                                width: '98%',
                                height: '660',
                                frameBorder: 1,
                                id: 'idIframe' + dashboard.id,
                            };

                            setLabDashboards(tempList);
                            setEmbedUrlFlag(true);
                        }).catch((error) => {
                            console.log("Error loading dashboards " + error);
                            setEmbedUrlFlag(true);

                            let tempList = [...labDashboards];
                            tempList[index].embedAttributes = {
                                src: '',
                                width: '98%',
                                height: '660',
                                frameBorder: 1,
                                id: 'idIframe' + dashboard.id,
                            };

                            setLabDashboards(tempList);
                        });
                }
            })
        };
        getEmbedUrl();
        return () => (isMountedRef.current = false);
    }, [idToken]);

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        top: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#223c89',
                    }}
                >
                    <Loader type="ThreeDots" color="#223c89" height="80" width="80" />
                </div>
            )}
            {!isLoading &&
                <Container>
                    <h1>Lab Conformance Reports</h1>
                    <Row>
                        <Col className="text-center">
                            <h6 style={{ marginBottom: '16px' }}>Please select from the following report options:</h6>
                        </Col>
                    </Row>
                    <Row xs={1} md={2} className="g-4">
                        {labDashboards.map((dashboard, index) => {
                            if (dashboard.embedAttributes) {
                                return (
                                    <Col className="item" key={index}>
                                        <OverlayTrigger
                                            key={index}
                                            placement="bottom"
                                            delay={{ hide: 100 }}
                                            overlay={
                                                <Tooltip id={index}>
                                                    {dashboard.description}
                                                </Tooltip>
                                            }
                                        >
                                            <Card>
                                                <Card.Header as="h5" className="text-center" onClick={() => handleShow(dashboard.name, dashboard.embedAttributes)}>{dashboard.name}</Card.Header>
                                            </Card>
                                        </OverlayTrigger>
                                    </Col>
                                )
                            }
                            return "";
                        })}
                    </Row>
                </Container>
            }
            <Modal show={show} onHide={handleClose} fullscreen>
                <ReportModal modalName={modalName} dashAttributes={dashAttributes} />
            </Modal>
        </>
    );
};

export default MedRecReports;
